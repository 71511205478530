import React, { useCallback, useState } from "react"
import { BootLayout } from "../../layout/BootLayout"
import { ProductSet } from "../ProductSet"
import { StringParam, useQueryParam } from "use-query-params"
import { ProductDetail } from "../ProductDetails"
import { PosterPopup } from "../PosterPopup"
import { Poster as PosterType } from "../Poster/Poster"
import { ProductDetails } from "../ProductDetails/ProductDetails"

const BootPageComponent: React.FC<{
  data: Props
  disableEffects: boolean
}> = ({ data: { edges }, disableEffects }) => {
  const [current, setCurrent] = useQueryParam("trend", StringParam)

  const onNavigate = useCallback(
    (order: number) => {
      const e = edges.find(e => e.node.order === order)
      e && setCurrent(e.node.parent.name)
    },
    [setCurrent]
  )

  const currentOrder =
    edges.find(e => e.node.parent.name === current)?.node.order || 1

  const [
    currentProductDetails,
    setCurrentProductDetails,
  ] = useState<ProductDetails | null>(null)

  const [videoVisible, setVideoVisible] = useState<boolean>(false)

  const onExplore = useCallback((product: ProductDetails) => {
    // @ts-ignore
    window.gtag?.("event", "product_click", {
      event_category: "product_click",
      event_label: product.name,
      value: 1,
    })
    setCurrentProductDetails(product)
  }, [])

  const closeOverlay = useCallback(() => {
    setCurrentProductDetails(null)
    setVideoVisible(false)
  }, [setCurrentProductDetails, setVideoVisible])

  const onPlayClick = useCallback(() => setVideoVisible(true), [
    setVideoVisible,
  ])

  return (
    <>
      <BootLayout
        currentItemOrder={currentOrder}
        navigate={onNavigate}
        disableEffects={
          !!currentProductDetails || videoVisible || disableEffects
        }
        closeOverlay={closeOverlay}
        overlay={
          (currentProductDetails && (
            <ProductDetail {...currentProductDetails} onClose={closeOverlay} />
          )) ||
          (videoVisible ? (
            <PosterPopup
              {...edges.find(e => e.node.order === currentOrder)!.node.poster}
              onClose={closeOverlay}
            />
          ) : null)
        }
      >
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ProductSet
              key={node.order}
              products={node.products}
              onProductClick={onExplore}
              poster={node.poster}
              onPosterClick={onPlayClick}
              focused={currentOrder === node.order}
              disableEffects={!current}
            />
          </React.Fragment>
        ))}
      </BootLayout>
      <div
        id="slides-preload"
        style={{
          position: "fixed",
          top: 0,
          width: 0,
          height: 0,
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        {edges
          .find(e => e.node.order === currentOrder)
          ?.node.products.map(p => (
            <ProductDetail {...p} onClose={() => {}} />
          ))}
      </div>
    </>
  )
}

export const BootPage = React.memo(BootPageComponent)

type Props = {
  edges: [
    {
      node: {
        id: string
        video?: string
        parent: { name: string }
        order: number
        name: string
        image?: { publicURL: string }
        products: [ProductDetails]
        poster: PosterType
      }
    }
  ]
}
