import React, { useContext, useEffect, useState } from "react"
import * as S from "./styled"
import { ImageSequence as ImageSequenceProps } from "./ImageSequence"
import { ImageSupportContext } from "@motherson-dxp/components"

type Props = ImageSequenceProps & {
  animate: boolean
  onAnimationEnd?: () => void
  loop?: boolean
  [other: string]: any
}

export const ImageSequence: React.FC<Props> = ({
  frameRate,
  images,
  animate,
  onAnimationEnd,
  loop,
  bottomPadding,
  webpAnimation,
  background,
  ...rest
}) => {
  const [currentFrame, setCurrentFrame] = useState<number>(0)
  const imageSupport = useContext(ImageSupportContext)

  useEffect(() => {
    if (!animate || imageSupport === "webp") {
      return
    }
    if (currentFrame === images.length - 1) {
      if (!loop) {
        onAnimationEnd && onAnimationEnd()
        return
      } else {
        setTimeout(() => setCurrentFrame(0), frameRate)
      }
      return
    }

    const timeout = setTimeout(
      () => setCurrentFrame(f => Math.min(f + 1, images.length - 1)),
      frameRate
    )
    return () => clearTimeout(timeout)
  }, [animate, currentFrame])

  return (
    <S.Container {...rest}>
      {background && (
        <S.Frame
          style={{
            paddingBottom: `${Math.max(bottomPadding || 0, 0)}px`,
            marginTop: `${Math.abs(Math.min(0, bottomPadding || 0))}px`,
          }}
          src={background.publicURL}
        />
      )}
      <S.Frame
        style={{
          paddingBottom: `${Math.max(bottomPadding || 0, 0)}px`,
          marginTop: `${Math.abs(Math.min(0, bottomPadding || 0))}px`,
        }}
        src={
          imageSupport === "webp" && webpAnimation?.publicURL
            ? webpAnimation.publicURL
            : images && images[currentFrame]?.publicURL
        }
      />
    </S.Container>
  )
}
