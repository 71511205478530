import styled, { css } from "styled-components"
import device from "../util/devices"

export const NavContainer = styled.nav`
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
  z-index: 2;

  @media (${device.desktop}) {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
`

export const OverlayContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  overflow: hidden;
  z-index: 50;
  display: flex;
  transition: opacity 250ms ease-out;
  ${props =>
    props.visible
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `};

  @media (${device.desktop}) {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
`

export const OverlayContainerInner = styled.div`
  width: 100vw;
  height: 100vh;
  margin: auto;
  max-width: 1050px;
  max-height: 100%;
  display: flex;
`

export const OverflowBackground = styled.div`
  background: rgba(39, 39, 39, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
