import styled from "styled-components"

export const RoundButton = styled.button`
  background: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;

  :focus {
    outline: none;
  }
`
