import React, { useEffect, useState } from "react";
import * as S from "./styled"
import ReactMarkdown from "react-markdown"
// @ts-ignore
import { ReactComponent as Close } from "../../../static/icons/ic-close.svg"
// @ts-ignore
import { ReactComponent as Play } from "../../../static/icons/ic-play.svg"
// @ts-ignore
import { ReactComponent as Pause } from "../../../static/icons/ic-pause.svg"
import { ProductDetails } from "./ProductDetails"
import "./fragments"
import { SlideBackground } from "./Background"
import { useSwipeable } from "react-swipeable"
import { EmptySlide } from "./EmptySlide"

export const ProductDetail: React.FC<
  ProductDetails & { onClose: () => void }
  > = props => {
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [backgroundSlideshowPaused, setBackgroundSlideshowPaused] = useState<boolean>(false)

  useEffect(() => {
    setBackgroundSlideshowPaused(false)
  }, [currentSlide])

  const navigationControls2 = React.useMemo(
    () => (
      <S.Navigation>
        {((props.slides?.[currentSlide]?.backgrounds?.length || 0) > 1)
          && <S.SlideshowControl onClick={() => setBackgroundSlideshowPaused(p => !p)}>
            <span>{backgroundSlideshowPaused ? "Resume" : "Pause"}</span>
            {backgroundSlideshowPaused ? <Play /> : <Pause />}
          </S.SlideshowControl>}
        {(props.slides?.length || 0) > 1 && (
          <S.Pager>
            <S.PagerArrow
              clickable={currentSlide > 0}
              pointLeft
              onClick={() => setCurrentSlide(s => s - 1)}
            />
            <span style={{ minWidth: "24px" }}>
              {currentSlide + 1} / {props.slides?.length}
            </span>
            <S.PagerArrow
              clickable={currentSlide < (props.slides?.length || 0) - 1}
              onClick={() => setCurrentSlide(s => s + 1)}
            />
          </S.Pager>
        )}
        <S.Close onClick={props.onClose}>
          <span>Close</span>
          <Close />
        </S.Close>
      </S.Navigation>
    ),
    [props.slides?.length, setCurrentSlide, currentSlide, props.onClose, backgroundSlideshowPaused]
  )

  const swipeableHandlers = useSwipeable({
    onSwipedRight: () => setCurrentSlide(Math.max(0, currentSlide - 1)),
    onSwipedLeft: () =>
      setCurrentSlide(
        Math.min(currentSlide + 1, (props.slides?.length || 0) - 1)
      ),
  })
  return (
    <>
      <div {...swipeableHandlers}>
        {props.slides ? (
          props.slides.map((slide, i) => (
            <SlideBackground
              key={i}
              visible={i === currentSlide}
              backgrounds={slide.backgrounds}
              paused={backgroundSlideshowPaused}
            >
              {slide.text && (
                <S.Content>
                  <ReactMarkdown>{slide.text}</ReactMarkdown>
                </S.Content>
              )}
            </SlideBackground>
          ))
        ) : (
          <EmptySlide />
        )}
        {navigationControls2}
      </div>
    </>
  )
}
