import React, { useEffect, useState } from "react"
import { SlideBackground as SlideBackgroundType } from "./ProductDetails"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"
import * as S from "./Background.styled"
import ReactPlayer from "react-player"

export const SlideBackground: React.FC<{
  backgrounds?: SlideBackgroundType[]
  visible: boolean
  paused?: boolean
}> = props => {
  const [currentSub, setCurrentSub] = useState(0)
  const [shouldGoToNextOnResult, setShouldGoToNextOnResume] = useState(false)
  useEffect(() => {
    if (props.paused) {
      setShouldGoToNextOnResume(true)
      return
    }
    if (shouldGoToNextOnResult) {
      setCurrentSub(s => (s + 1) % (props.backgrounds?.length || 1))
    }
    const id = setInterval(() => {
      setCurrentSub(s => (s + 1) % (props.backgrounds?.length || 1))
    }, 4500)
    return () => clearInterval(id)
  }, [props.visible, props.paused])

  return (
    <S.Container visible={props.visible}>
      {props.backgrounds?.map((bg, i) => (
        <S.SubSlide key={JSON.stringify(bg)} visible={currentSub === i}>
          {bg.type === "image" ? (
            <GatsbyImageWithIEPolyfill
              fluid={bg.image.childImageSharp.fluid}
              durationFadeIn={1250}
              fadeIn
              backgroundColor={bg.image.colors?.vibrant}
              style={{ height: "100%", width: "100%" }}
            />
          ) : (
            <ReactPlayer
              url={bg.video}
              width="100%"
              height="100%"
              controls={false}
              playing={props.visible && i === currentSub}
              playsinline
              loop={props.backgrounds?.length === 1 || props.paused}
              volume={0.75}
            />
          )}
        </S.SubSlide>
      ))}
      <S.Overlay withBg={false}>{props.children}</S.Overlay>
    </S.Container>
  )
}
