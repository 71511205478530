import React, { useEffect, useState } from "react"
import { Poster as PosterType } from "../Poster/Poster"
import { ProductDetails } from "../ProductDetails/ProductDetails"
import { Poster } from "../Poster"
import { Product } from "../Product"

type Props = {
  products: ProductDetails[]
  onProductClick: (p: ProductDetails) => void
  poster: PosterType
  onPosterClick: () => void
  focused: boolean
  disableEffects: boolean
}

const ProductSetComponent: React.FC<Props> = ({
  poster,
  onPosterClick,
  products,
  onProductClick,
  focused,
  disableEffects,
}) => {
  const [animating, setAnimating] = useState<boolean>(false)
  useEffect(() => {
    if (!focused) {
      setTimeout(() => setAnimating(false), 1000)
      return
    }
    setAnimating(true)
  }, [focused])

  return (
    <>
      <Poster {...poster} onPlay={onPosterClick} />
      {products.map((product, i) => (
        <Product
          key={product.id}
          details={product}
          onExploreClick={onProductClick}
          animate={!disableEffects && animating}
        />
      ))}
    </>
  )
}

export const ProductSet = React.memo(ProductSetComponent)
