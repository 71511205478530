import styled, { css } from "styled-components"

//@ts-ignore
import backgroundImage from "../images/bg.png"
//@ts-ignore
import middle1 from "../images/mid1.png"
//@ts-ignore
import middle2 from "../images/mid2.png"
//@ts-ignore
import foreground from "../images/fg.png"

export const backgrounds = [
  backgroundImage,
  middle1,
  middle2,
  foreground,
] as string[]

export const Outer = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 650ms ease-in-out;
`

const verticalInnerTransform = (aspectRatio: number) => {
  // Wider devices. shift a bit up
  return aspectRatio > 16 / 9 ? -54 : -50
}

export const Inner = styled.div<{
  aspectRatio: number
}>`
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translate3d(
    -50%,
    ${({ aspectRatio }) => verticalInnerTransform(aspectRatio)}%,
    0
  );
  ${props =>
    props.aspectRatio <= 16 / 9
      ? css`
          height: 100%;
          > img {
            height: 178vh;
          }
        `
      : css`
          width: 100%;
          padding-top: calc(9 / 16 * 100%);
        `}
`

const Layer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: 100%;
  background-position: 50%;
`

export const BackgroundLayer = styled(Layer)`
  background-image: url("${backgroundImage}");
`

export const MiddleLayer = styled(Layer)``

export const ForegroundLayer = styled(Layer)`
  background-image: url("${foreground}");
`

export const Boot = styled.div`
  position: relative;
  flex: 1 1 auto;
  &:nth-child(odd) > ${MiddleLayer} {
    background-image: url("${middle1}");
  }

  &:nth-child(even) > ${MiddleLayer} {
    background-image: url("${middle2}");
  }
`

export const LayerRow = styled.div<{
  index: "bg" | "middle" | "fg"
  withTransitionDuration: boolean
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  // TODO de-hardcode. should be (count + 2) * 100
  width: 800%;
  display: flex;
  transition: ${({ withTransitionDuration }) =>
    withTransitionDuration ? 1 : 0}s;
  will-change: transform;
  filter: blur(
    ${({ index }) => (index === "bg" ? "0.4" : index === "middle" && "0.05")}rem
  );
`
