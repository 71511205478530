import React from "react"
import { ImageSequence } from "../ImageSequence"
import { ProductDetails } from "../ProductDetails/ProductDetails"
import * as S from "./styled"

type ProductProps = {
  details: ProductDetails
  onExploreClick: (product: ProductDetails) => void
  animate: boolean
}

const ProductComponent = ({
  details,
  onExploreClick,
  animate,
}: ProductProps) => {
  return (
    <S.Container
      onClick={() => onExploreClick(details)}
      //@ts-ignore
      clickable
    >
      <S.ThumbnailContainer>
        {details.imageSequence && (
          <ImageSequence
            className="product-img"
            animate={animate}
            loop
            {...details.imageSequence}
          />
        )}
      </S.ThumbnailContainer>
      <S.Title>{details.name}</S.Title>
      <S.Link onClick={() => onExploreClick(details)}>Explore</S.Link>
    </S.Container>
  )
}

export const Product = React.memo(ProductComponent)
