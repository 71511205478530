import React from "react"
import { Room } from "./Room"
import { TrendBootNav } from "../components/TrendBootNav"
import * as S from "./BootLayout.styled"
import { ForcedAspectRatioBox } from "../components/ForcedAspectRatioBox"
import { useSwipeable } from "react-swipeable"

export const BootLayout: React.FC<{
  currentItemOrder: number
  navigate: (order: number) => void
  disableEffects: boolean
  overlay?: JSX.Element | null
  closeOverlay: () => void
}> = props => {
  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => props.navigate(props.currentItemOrder + 1),
    onSwipedRight: () => props.navigate(props.currentItemOrder - 1),
  })
  return (
    <>
      <Room
        disableEffects={props.disableEffects || false}
        current={props.currentItemOrder - 1}
        innerProps={swipeableHandlers}
      >
        {props.children}
      </Room>
      <TrendBootNav
        as={S.NavContainer}
        current={props.currentItemOrder}
        onNavigate={props.navigate}
      />
      <S.OverlayContainer visible={!!props.overlay}>
        <S.OverlayContainerInner>
          <S.OverflowBackground onClick={props.closeOverlay} />
          <ForcedAspectRatioBox
            ratio={16 / 9}
            style={{
              backgroundColor: props.overlay ? "black" : null,
              margin: "auto",
            }}
            maxWidth={1050}
          >
            {props.overlay}
          </ForcedAspectRatioBox>
        </S.OverlayContainerInner>
      </S.OverlayContainer>
    </>
  )
}
