import * as React from "react"
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { graphql, PageProps, navigate } from "gatsby"

import SEO from "../components/seo"
import {
  Preloader as Loader,
  Preloadable,
  ImageSupportContext,
} from "@motherson-dxp/components"
import ReactPlayer from "react-player"
import { ArrowedButton } from "../components/ArrowedButton"
import { PageHeader } from "../context/HeaderPropsContext"
import { backgrounds } from "../layout/Room.styled"
import { baseBackground } from "../layout/BaseLayout"
import { MenuContext } from "../context/MenuContext"
import { ViewportContext } from "../context/ViewportContext"
import { ProductDetails } from "../components/ProductDetails/ProductDetails"
import { BootPage } from "../components/TrendBootPage/boot"
import { BooleanParam, useQueryParam } from "use-query-params"
import { Poster } from "../components/Poster/Poster"
import { ImageSequence } from "../components/ImageSequence/ImageSequence"
import { ClickToEnter } from "../components/ClickToEnter"

type DataProps = {
  intro: {
    video: string
    videoFile?: {
      publicURL: string
    }
  }
  sequences: {
    edges: [{ node: ImageSequence }]
  }
  boots: {
    edges: [
      {
        node: {
          id: string
          parent: { name: string }
          order: number
          name: string
          products: [ProductDetails]
          poster: Poster
        }
      }
    ]
  }
}

const Index: React.FC<PageProps<DataProps>> = ({
  data: { intro, boots, sequences },
  location,
}) => {
  const [inBoot] = useQueryParam("boot", BooleanParam) as boolean[]
  const [transitionEffectEnabled, setTransitionEffectEnabled] = useState<
    boolean
  >(false)

  const playerRef = createRef<ReactPlayer>()

  useEffect(() => {
    if (location.hash) {
      navigate("/", { replace: true })
      playerRef.current?.seekTo(0)
      _setIntroEnded(false)
    }
  }, [location.hash])

  useEffect(() => {
    inBoot && setTimeout(() => setTransitionEffectEnabled(inBoot), 650)
    if (!inBoot) {
      loaded && playerRef.current?.seekTo(0)
      _setIntroEnded(false)
    }
  }, [inBoot])

  const video = intro.videoFile?.publicURL || intro.video

  const [loaded, _setLoaded] = useState(false)
  const setLoaded = useCallback(() => _setLoaded(true), [_setLoaded])

  const imageSupport = useContext(ImageSupportContext)

  const preload = useMemo(() => {
    if (!imageSupport) return []
    let items = [...backgrounds, baseBackground]
      .map(bg => ({
        type: "image",
        url: bg,
      }))
      .concat([{ type: "video", url: video }])

    items = items.concat(
      sequences.edges
        .filter(s => !!s.node.background)
        .map(
          e =>
            ({
              type: "image",
              url: e.node.background?.publicURL,
            } as Preloadable)
        )
    )

    if (imageSupport === "webp") {
      items = items.concat(
        sequences.edges
          .filter(s => s.node.webpAnimation?.publicURL)
          .map(
            s =>
              ({
                type: "image",
                url: s.node.webpAnimation?.publicURL,
              } as Preloadable)
          )
      )
    } else {
      items = items.concat(
        sequences.edges.reduce(
          (prev, current) => [
            ...prev,
            ...current.node.images.map(
              i =>
                ({
                  type: "image",
                  url: i.publicURL,
                } as Preloadable)
            ),
          ],
          [] as Preloadable[]
        )
      )
    }

    return items
  }, [imageSupport])

  const [introEnded, _setIntroEnded] = useState(false)
  const setIntroEnded = useCallback(() => _setIntroEnded(true), [
    _setIntroEnded,
  ])

  const { setMenuOpen } = useContext(MenuContext)

  const SkipIntroButton = React.useMemo(
    () =>
      !introEnded && !inBoot ? (
        <ArrowedButton clickable onClick={setIntroEnded}>
          Skip intro
        </ArrowedButton>
      ) : null,
    [introEnded, setIntroEnded, inBoot]
  )

  useEffect(() => {
    introEnded && setMenuOpen()
    introEnded && playerRef.current?.seekTo(1)
  }, [introEnded])

  const [entered, setEntered] = useState(false)

  const { viewportReady } = useContext(ViewportContext)
  return (
    <>
      <SEO />
      <Loader
        onReady={setLoaded}
        //@ts-ignore
        preload={preload}
      />
      {viewportReady && (
        <>
          <PageHeader
            menuButtonVisible={introEnded || inBoot}
            menuContent={SkipIntroButton}
          />

          <ClickToEnter skip={inBoot} onEnter={() => setEntered(true)} />

          {entered && (
            <ReactPlayer
              ref={playerRef}
              url={video}
              playing={entered && !introEnded && !inBoot}
              width={"100%"}
              onProgress={({ playedSeconds }) =>
                Math.round(playedSeconds) === 4 && setMenuOpen()
              }
              className={"fill fill-video"}
              style={{
                zIndex: 30,
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: inBoot ? 0 : 1,
                transition: "opacity 350ms ease-out",
                pointerEvents: inBoot ? "none" : null,
              }}
              height={"100%"}
              onEnded={setIntroEnded}
              playsinline
            />
          )}

          {loaded && (
            <BootPage data={boots} disableEffects={!transitionEffectEnabled} />
          )}
        </>
      )}
    </>
  )
}

export default Index

export const query = graphql`
  {
    intro: introYaml {
      video
      videoFile {
        publicURL
      }
    }
    boots: allTrendBootYaml(sort: { fields: order }) {
      edges {
        node {
          id
          order
          name
          parent {
            ... on File {
              name
            }
          }
          __typename
          products {
            ...ProductDetails
          }
          ...PosterDetails
        }
      }
    }
    sequences: allSequenceYaml {
      edges {
        node {
          ...ImageSequence
        }
      }
    }
  }
`
