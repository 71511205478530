import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
`
