import React from "react"
import { ThemeProvider } from "styled-components"
import { LoaderTheme } from "../../theme/LoaderTheme"
import * as S from "./EmptySlide.styled"

export const EmptySlide: React.FC = () => (
  <ThemeProvider theme={LoaderTheme}>
    <S.Container>Content to come</S.Container>
  </ThemeProvider>
)
