import styled from "styled-components"
import device from "../../util/devices"

export const Container = styled.div<{ visible: boolean }>`
  height: 100%;
  width: 100%;
  position: absolute;
  transition: opacity 250ms ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

export const SubSlide = styled.div<{ visible: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: opacity 650ms ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

export const Overlay = styled.div<{ withBg: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 15px 10px;
  background: ${props =>
    props.withBg
      ? `linear-gradient(
    180deg,
    rgba(0, 0, 0, 0),
    transparent 10%,
    transparent,
    transparent 85%,
    rgba(0, 0, 0, 0.45)
  );`
      : null};
  pointer-events: none;

  @media (${device.tablet}) {
    padding: 15px 13px;
  }

  @media (${device.desktop}) {
    padding: 15px 20px 30px;
  }
`
