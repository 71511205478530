import React, { useEffect, useState } from "react"
import * as S from "./Room.styled"

export const Room: React.FC<{
  current: number
  disableEffects: boolean
  outerProps?: any
  innerProps?: any
}> = ({ current, disableEffects, ...props }) => {
  const [aspectRatio, setAspectRatio] = useState<number>(17 / 9)
  const onWindowResize = (currentBooth: number) => {
    setAspectRatio(window.innerWidth / window.innerHeight)
    setShift(s => ({ ...s, ...calculateLayerShift(currentBooth) }))
  }

  const calculateLayerShift = (current: number) => {
    const bootWidth = document.getElementById("boot-ref")?.offsetWidth || 0
    return {
      bgLayer: (current + 2) * 0.5 * bootWidth * -1,
      fgLayer: (current + 1) * bootWidth * -1,
      middleLayer: (current + 1) * bootWidth * -1,
    }
  }

  const [shift, setShift] = useState<{
    bg: number
    middle: number
    fg: number
    bgLayer: number
    middleLayer: number
    fgLayer: number
  }>({ bg: 0, middle: 0, fg: 0, middleLayer: 0, bgLayer: 0, fgLayer: 0 })
  const onMouseMove = (e: MouseEvent) => {
    const delta = ((e.pageX / window.innerWidth) * 100 - 50) * -1
    setShift(s => ({
      ...s,
      bg: Math.round(delta),
      middle: Math.round(delta * 0.75),
      fg: Math.round(delta * 0.25),
    }))
  }

  useEffect(() => {
    if (disableEffects) return
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
      return
    window.addEventListener("mousemove", onMouseMove)
    return () => window.removeEventListener("mousemove", onMouseMove)
  }, [disableEffects])

  useEffect(() => {
    const onResize = () => onWindowResize(current)
    window.addEventListener("resize", onResize)
    setTimeout(() => onResize(), 300)
    return () => window.removeEventListener("resize", onResize)
  }, [current])

  const count = React.Children.count(props.children)

  useEffect(() => {
    setShift(s => ({ ...s, ...calculateLayerShift(current) }))
  }, [current])

  return (
    <S.Outer visible {...props.outerProps}>
      <S.Inner aspectRatio={aspectRatio} {...props.innerProps}>
        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
        <S.LayerRow
          withTransitionDuration={!disableEffects}
          className="boot-row--bg"
          index={"bg"}
          style={{
            transform: `translate3d(${shift.bgLayer}px,0,0)`,
          }}
        >
          {Array(count + 2)
            .fill(0)
            .map((_, i) => (
              //@ts-ignore
              <S.Boot key={i} id={i === 0 ? "boot-ref" : null}>
                <S.BackgroundLayer
                  style={{ transform: `translate3d(${shift.bg}px,0,0)` }}
                />
              </S.Boot>
            ))}
        </S.LayerRow>
        <S.LayerRow
          withTransitionDuration={!disableEffects}
          className="boot-row--middle"
          index={"middle"}
          style={{
            transform: `translate3d(${shift.middleLayer}px,0,0)`,
          }}
        >
          {Array(count + 2)
            .fill(0)
            .map((_, i) => (
              <S.Boot key={i}>
                <S.MiddleLayer
                  style={{ transform: `translate3d(${shift.middle}px,0,0)` }}
                />
              </S.Boot>
            ))}
        </S.LayerRow>
        <S.LayerRow
          withTransitionDuration={!disableEffects}
          className="boot-row--fg"
          index={"fg"}
          style={{
            transform: `translate3d(${shift.fgLayer}px,0,0)`,
          }}
        >
          <S.Boot>
            <S.ForegroundLayer
              style={{ transform: `translate3d(${shift.fg}px,0,0)` }}
            />
          </S.Boot>
          {React.Children.map(props.children, (c, i) => (
            <S.Boot key={i}>
              <S.ForegroundLayer
                style={{ transform: `translate3d(${shift.fg}px,0,0)` }}
              >
                {c}
              </S.ForegroundLayer>
            </S.Boot>
          ))}
          <S.Boot>
            <S.ForegroundLayer
              style={{ transform: `translate3d(${shift.fg}px,0,0)` }}
            />
          </S.Boot>
        </S.LayerRow>
      </S.Inner>
    </S.Outer>
  )
}
