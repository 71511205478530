import React, { MutableRefObject, useEffect, useState } from "react"
import * as S from "./styled"
import * as ThumbStyles from "../Poster/styled"
// @ts-ignore
import { ReactComponent as Close } from "../../../static/icons/ic-close.svg"
// @ts-ignore
import { ReactComponent as Pause } from "../../../static/icons/ic-pause.svg"
// @ts-ignore
import { ReactComponent as Play } from "../../../static/icons/ic-play.svg"
// @ts-ignore
import { ReactComponent as FullScreenIcon } from "../../../static/icons/ic-full-screen.svg"
import ReactPlayer from "react-player"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Poster } from "../Poster/Poster"
import { useMedia } from "use-media"
import { size } from "../../util/devices"

type Props = Poster & {
  onClose?: () => void
  [other: string]: any
}

export const PosterPopup: React.FC<Props> = props => {
  const [playing, setPlaying] = useState(true)
  const playerRef = React.useRef() as MutableRefObject<ReactPlayer>

  const handle = useFullScreenHandle()

  const isDesktop = useMedia({ minWidth: size.desktop })

  return (
    <FullScreen handle={handle}>
      <ReactPlayer
        url={props.media![0].source || props.media![0].file?.publicURL}
        ref={playerRef}
        controls={false}
        playing={playing}
        playsinline
        onEnded={() => {
          playerRef.current.seekTo(0)
          setPlaying(false)
        }}
        volume={0.75}
        width="100%"
        height="100%"
      />
      <S.Overlay>
        <S.TitleContainer>
          <ThumbStyles.Title>{props.title}</ThumbStyles.Title>
          {props.description && (
            <ThumbStyles.Subtitle>{props.description}</ThumbStyles.Subtitle>
          )}
        </S.TitleContainer>
        <S.ControlsContainer>
          <S.Control onClick={() => setPlaying(p => !p)}>
            {playing ? (
              <>
                <Pause /> Pause
              </>
            ) : (
              <>
                <Play /> Play
              </>
            )}
          </S.Control>
          {isDesktop && (
            <S.Control
              onClick={() => (handle.active ? handle.exit : handle.enter)()}
            >
              <FullScreenIcon /> Full screen
            </S.Control>
          )}
        </S.ControlsContainer>
        {props.onClose && (
          <S.CloseContainer onClick={props.onClose}>
            <span>Close</span>
            <Close />
          </S.CloseContainer>
        )}
      </S.Overlay>
    </FullScreen>
  )
}
