import styled from "styled-components"
import device from "../../util/devices"

export const Content = styled.div`
  height: 100%;
  position: relative;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  @media (${device.desktop}) {
    font-size: 21px;
    line-height: 26px;
  }
`

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  padding-top: 5px;
`

export const PlayLink = styled.div`
  font-size: 15px;
  line-height: 17px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 6px;
    path {
      stroke: ${({ theme }) => theme.primaryColor};
    }
  }

  @media (${device.desktop}) {
    margin-top: 30px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 5px 10px 8px;
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.45),
    transparent 240px
  );
  user-select: none;

  @media (${device.desktop}) {
    padding: 30px 40px 20px 20px;
  }
`

export const Container = styled.section<{ clickable: boolean }>`
  position: absolute;
  left: 8.7%;
  bottom: 13%;
  height: 73%;
  width: 17.55%;
  cursor: ${props => props.clickable && "pointer"};
  max-height: 90vh;

  video {
    object-fit: cover;
  }
`

export const PlayWrapper = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  margin-right: 6px;
`

export const PulsatingCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 120%;
    height: 120%;
    box-sizing: border-box;
    margin-left: -7%;
    margin-top: -7%;
    border-radius: 45px;
    background-color: ${({ theme }) => theme.primaryColor};
    animation: pulse-ring 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;

    @media (${device.desktop}) {
      width: 200%;
      height: 200%;
      margin-left: -50%;
      margin-top: -50%;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.43);
      opacity: 0.6;
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`
