import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ArrowedButton } from "../ArrowedButton"

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #130304;
  z-index: 400;
  transition: opacity 550ms ease-out;
  ${props =>
    !props.visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `};

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    cursor: pointer;
  }
`

export const ClickToEnter: React.FC<{
  onEnter: () => void
  skip?: boolean
}> = props => {
  const [clicked, setClicked] = useState(false)
  return (
    <Container visible={!(props.skip || clicked)}>
      <ArrowedButton
        withBackground
        onClick={() => {
          setClicked(true)
          props.onEnter()
        }}
      >
        Enter future exhibition
      </ArrowedButton>
    </Container>
  )
}
