import React from "react"
import * as S from "./styled"
// @ts-ignore
import { ReactComponent as Play } from "../../../static/icons/ic-play.svg"
import { Poster as PosterType } from "./Poster"

type PosterProps = PosterType & {
  onPlay: () => void
}

export const Poster: React.FC<PosterProps> = props => {
  const hasVideo: boolean = !!props.media && props.media.length > 0
  return (
    <S.Container
      clickable={hasVideo}
      onClick={hasVideo ? props.onPlay : () => {}}
    >
      <S.Content>
        <img
          src={props.image.publicURL}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
        <S.Overlay>
          <S.Title>{props.title}</S.Title>
          {props.description && <S.Subtitle>{props.description}</S.Subtitle>}
          {hasVideo && (
            <S.PlayLink onClick={props.onPlay}>
              <S.PlayWrapper>
                <Play />
                <S.PulsatingCircle />
              </S.PlayWrapper>{" "}
              <span style={{ zIndex: 20 }}>Play video</span>
            </S.PlayLink>
          )}
        </S.Overlay>
      </S.Content>
    </S.Container>
  )
}
