import styled from "styled-components"

export const Container = styled.div`
  position: relative;
`

export const Frame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  max-width: 100%;
  will-change: src;
`
