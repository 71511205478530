import styled, { css } from "styled-components"
import device from "../../util/devices"
import { RoundButton } from "../RoundButton"
//@ts-ignore
import { ReactComponent as ArrowRight } from "../../../static/icons/ic-arrow-right.svg"

export const Content = styled.div`
  z-index: 1;
  position: relative;
  background: #130304;
  color: white;
  padding: 17px 20px;
  margin-top: auto;
  max-width: 375px;
  max-height: 245px;
  overflow-y: auto;
  opacity: 0.9;
  box-sizing: border-box;

  pointer-events: auto;

  @media (${device.tablet}) {
    margin-right: auto;
  }

  @media (${device.desktop}) {
    margin-bottom: auto;
    margin-top: 0;
    max-height: 80%;
  }

  font-weight: 300;
  font-size: 14px;
  line-height: 15px;

  h1 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 300;
  }
`

export const Pager = styled(RoundButton)`
  pointer-events: auto;
  color: white;
  background: ${props => props.theme.primaryColor};
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-right: 8px;

  @media (${device.desktop}) {
    margin-right: 15px;
  }
`

export const PagerArrow = styled(ArrowRight)<{
  clickable: boolean
  pointLeft?: boolean
}>`
  padding: 10px;
  transform: rotate(${({ pointLeft }) => (pointLeft ? 180 : 0)}deg);
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
          opacity: 0.5;
        `}
`

export const Navigation = styled.nav`
  pointer-events: auto;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;

  position: absolute;
  right: 8px;
  top: 16px;
  display: flex;
  align-items: end;
`

export const SlideshowControl = styled(RoundButton)`
pointer-events: auto;
  margin-right: 12px;

  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;

  svg {
    margin: 10px;
  }

  span {
    max-width: 0;
    transition: all 250ms ease-out;
    box-sizing: border-box;
    overflow: hidden;
  }
  

  :hover {
    span {
      max-width: 60px;
      padding-left: 10px;
    }
  }`

export const Close = styled(RoundButton)`
  pointer-events: auto;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;

  svg {
    margin: 10px;
  }

  span {
    max-width: 0;
    transition: all 250ms ease-out;
    box-sizing: border-box;
    overflow: hidden;
  }

  :hover {
    span {
      max-width: 50px;
      padding-left: 10px;
    }
  }
`
