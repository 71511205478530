import styled from "styled-components"
import device from "../../util/devices"
import { ArrowedButton } from "../ArrowedButton"

export const Container = styled.article<{ clickable: boolean }>`
  position: absolute;
  bottom: 13%;
  height: 26%;
  width: 19.25%;
  cursor: ${props => props.clickable && "pointer"};
  transition: 0.25s;

  box-sizing: border-box;

  padding: 0 5px 10px;

  @media (${device.desktop}) {
    padding: 0 15px 20px;
  }

  :nth-of-type(1) {
    left: 29.85%;
  }
  :nth-of-type(2) {
    left: 50.85%;
  }
  :nth-of-type(3) {
    left: 72.1%;

    // fix for rightmost product's perspective
    div:first-child {
      margin-left: -4%;
      margin-right: 4%;
    }
  }

  .product-img {
    transition: transform 250ms ease-in-out;
    transform-origin: 50% 100%;
  }
  :hover .product-img {
    transform: scale(1.1);
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;

  @media (${device.desktop}) {
    font-size: 14px;
    line-height: 15px;
  }
`

export const Link = styled(ArrowedButton)`
  padding-left: 0;
  padding-top: 12px;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`

export const ThumbnailContainer = styled.div`
  position: relative;
  padding-bottom: 5px;
  height: 0;

  @media (${device.desktop}) {
    padding-bottom: 15px;
  }
  > div:first-child {
    max-width: 100%;
    padding-bottom: 10px;

    img {
      transform: translateY(-100%);
    }

    @media (${device.desktop}) {
      padding-bottom: 0;
    }
  }
`
