import styled, { css } from "styled-components"
import { RoundButton } from "../RoundButton"
import device from "../../util/devices"

export const Container = styled.nav`
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  > :first-child {
    margin-right: auto;
    grid-column: 1;
  }
  > :nth-child(2) {
    margin: 0 auto;
    grid-column: 2;
  }
  > :last-child {
    margin-left: auto;
    grid-column: 3;

    button {
      margin-left: auto;
    }
  }
`

export const Button = styled(RoundButton)<{ isLink?: boolean }>`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  user-select: none;
  i {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    :first-child {
      transform: rotate(180deg);
    }
  }

  div {
    max-width: 0;
    overflow: hidden;
    transition: max-width 250ms ease-out;
    white-space: nowrap;

    &:last-child:first-child {
      max-width: none;
      margin: 0 12px;
    }
  }
  ${props =>
    props.isLink &&
    css`
      cursor: pointer;
      @media (${device.desktop}) {
        &:hover {
          > :not(i) {
            max-width: 220px;
            :first-child {
              margin-left: 20px;
            }

            :last-child {
              margin-right: 20px;
            }
          }
        }
      }
    `}
`

export const Order = styled.span`
  font-weight: 600;
`
