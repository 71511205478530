import styled from "styled-components"
import React, { MutableRefObject, useEffect, useRef, useState } from "react"

const Outer = styled.div<{ ratio: number; maxWidth: number }>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: ${props => props.maxWidth}px;
  max-height: ${props => props.maxWidth / props.ratio}px;
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ForcedAspectRatioBox: React.FC<{
  ratio: number
  maxWidth: number
  [other: string]: any
}> = ({ maxWidth, ratio, ...props }) => {
  const outerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
  const [computedMaxWidth, setComputedMaxWidth] = useState<number>(-1)

  const onResize = () => {
    const parent = outerRef.current?.parentElement
    if (!parent) {
      return
    }
    setComputedMaxWidth(
      Math.min(parent.clientHeight * ratio, parent.clientWidth)
    )
  }

  useEffect(() => {
    window.addEventListener("resize", onResize)
    onResize()
    return () => window.removeEventListener("resize", onResize)
  })
  return (
    <Outer
      maxWidth={Math.min(computedMaxWidth, maxWidth)}
      ratio={ratio}
      ref={outerRef}
      {...props}
    >
      <Inner>{props.children}</Inner>
    </Outer>
  )
}
