import React from "react"
import * as S from "./styled"
// @ts-ignore
import { ReactComponent as Arrow } from "../../../static/icons/ic-arrow-right.svg"
import { graphql, useStaticQuery } from "gatsby"

export type TrendBootNavProps = {
  current: number
  [other: string]: any
  onNavigate: (to: number) => void
}

export const TrendBootNav: React.FC<TrendBootNavProps> = ({
  onNavigate,
  current,
  ...props
}) => {
  const { boots }: QueryData = useStaticQuery(query)

  const previous = boots.edges.find(e => e.node.order === current - 1)?.node
  const currentBooth = boots.edges.find(e => e.node.order === current)?.node
  const max = boots.edges.find(e => e.node.order === current + 1)?.node

  return (
    <S.Container {...props}>
      <div>
        {previous && (
          <S.Button
            id="#first"
            onClick={() => onNavigate(previous.order)}
            isLink
          >
            <i>
              <Arrow />
            </i>
            <div>
              <S.Order>
                Previous: {`${previous.order}`.padStart(2, "0")}.{" "}
              </S.Order>
              {previous.name}
            </div>
          </S.Button>
        )}
      </div>

      <S.Button>
        {currentBooth && (
          <div>
            <S.Order>{`${currentBooth.order}`.padStart(2, "0")}. </S.Order>

            {currentBooth.name}
          </div>
        )}
      </S.Button>

      <div>
        {max && (
          <S.Button id="last" onClick={() => onNavigate(max.order)} isLink>
            <div>
              <S.Order>Next: {`${max.order}`.padStart(2, "0")}. </S.Order>
              {max.name}
            </div>
            <i>
              <Arrow />
            </i>
          </S.Button>
        )}
      </div>
    </S.Container>
  )
}

type BootNode = {
  order: number
  __typename: string
  name: string
  parent: {
    name: string
  }
}

type QueryData = {
  boots: {
    edges: [
      {
        node: BootNode
      }
    ]
  }
}

const query = graphql`
  {
    boots: allTrendBootYaml {
      edges {
        node {
          id
          order
          __typename
          name
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`
