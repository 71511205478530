import styled from "styled-components"
import device from "../../util/devices"
import { RoundButton } from "../RoundButton"

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.45),
    transparent 15%,
    transparent 90%,
    rgba(0, 0, 0, 0.45)
  );

  padding: 7px 10px 10px 9px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  color: white;
  @media (${device.tablet}) {
    padding: 12px 13px 13px 16px;
  }

  @media (${device.desktop}) {
    padding: 20px 26px 20px 25px;
  }
`

export const TitleContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  margin-bottom: auto;
  margin-right: auto;

  > :nth-child(2) {
    margin-top: 8px;
  }
`

export const CloseContainer = styled(RoundButton)`
  grid-row: 1;
  grid-column: 2;
  margin-bottom: auto;
  margin-left: auto;
  cursor: pointer;

  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 10px;
  line-height: 12px;

  svg {
    margin: 10px;
  }

  span {
    max-width: 0;
    transition: all 250ms ease-out;
    box-sizing: border-box;
    overflow: hidden;
  }

  :hover {
    span {
      max-width: 50px;
      padding-left: 10px;
    }
  }
`

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 9px;
  @media (${device.tablet}) {
    bottom: 13px;
    left: 16px;
  }

  @media (${device.desktop}) {
    bottom: 26px;
    left: 25px;
  }
`

export const Control = styled.button`
  border: none;
  background: none;
  outline: none;
  color: white;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
  min-width: 70px;
  text-align: left;
  svg {
    margin-right: 8px;
    color: ${({ theme }) => theme.primaryColor};
    width: 11px;
    height: 11px;
  }
`
